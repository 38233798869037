import { DependencyList, useEffect, useRef } from 'react';

export default function useEffectAfterMount(fn: { (): void; (): void; (): any; }, deps: DependencyList) {
   const isMounted = useRef(false);
   useEffect(() => {
      let val: () => void;
      if (isMounted.current) {
         val = fn();
      }
      isMounted.current = true;
      return () => {
         if (typeof val == 'function') val();
      };
   }, deps);
}
