import React, { Children, ReactNode, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperClass from 'swiper/types/swiper-class'
import SwiperCore, {
   EffectFade,
   Navigation,
   Pagination,
   Autoplay,
   Scrollbar,
} from 'swiper'

import 'swiper/swiper.scss'
import './main-slider.scss'
// import "swiper/components/navigation/navigation.scss";
import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/scrollbar/scrollbar.scss';
// import 'swiper/components/effect-fade/effect-fade.scss'
import useEffectAfterMount from '@/services/helpers/useEffectAfterMount'
import 'swiper/components/scrollbar/scrollbar.scss'

SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar])

interface MainSlider {
   spaceBetween: any
   slidesPerView: any
   children?: any
   fade?: Boolean
   title?: ReactNode
   className?: any
   loading?: ReactNode
   delay?: any
   autoplay?: Boolean | any
   style?: any
   onSlideChange?: any
   centeredSlides?: false
   loop?: Boolean
   autoHeight?: Boolean
   scrollbar?: any
}
const MainSlider = (props: MainSlider) => {
   const sliderRef = useRef(null)
   const {
      spaceBetween,
      title = '',
      fade,
      className,
      autoplay = false,
      slidesPerView,
      loading,
      children,
      delay = null,
      centeredSlides,
      style,
      onSlideChange,
      loop,
      autoHeight,
      scrollbar = false,
      navigation = true,
      initialSlide = 0,
      breakpoints = null,
   } = props

   const ref = useRef<SwiperClass>(null)

   useEffectAfterMount(() => {
      ref.current.init()
   }, [slidesPerView, JSON.stringify(loading)])

   // useEffect(() => {
   //   const swiperParent = sliderRef.current
   //   if (swiperParent.getElementsByClassName('swiper-slide').length === 0) {
   //     swiperParent.querySelector('div.swiper-button-prev').style.display =
   //       'none'
   //     swiperParent.querySelector('div.swiper-button-next').style.display =
   //       'none'
   //   }
   // }, [children])
   let hasArrow = children?.length ? true : false
   if (navigation) {
      hasArrow = children?.length ? true : false
   } else {
      hasArrow = false
   }

   return (
      <>
         <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            className={className}
            onInit={(swiper) => {
               ref.current = swiper
            }}
            autoHeight={autoHeight}
            effect={fade ? 'fade' : 'slide'}
            autoplay={autoplay && { delay: delay, disableOnInteraction: true, pauseOnMouseEnter: false, }}
            pagination={{
               clickable: true,
            }}
            navigation={hasArrow}
            style={style}
            onSlideChange={onSlideChange}
            centeredSlides={centeredSlides}
            loop={loop}
            ref={sliderRef}
            scrollbar={scrollbar}
            initialSlide={initialSlide}
            breakpoints={breakpoints}
         >
            {children}
            {/* {React.Children.map(children, (child, key) => {
          return React.createElement(SwiperSlide, { key }, child);
        })} */}
         </Swiper>
      </>
   )
}

export { SwiperSlide }

export default MainSlider
