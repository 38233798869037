import './product-slider.scss'
import ProductTile, { ProductTileLoader } from '@/theme/components/ProductTile'
import MainSlider, { SwiperSlide } from '@/theme/components/MainSlider'
import { useAppContext } from '@/core/App/AppContext'
import { getProductDefaultCartItem } from 'src/services/product.query.modifier'
import Link from '@/core/Link'
import { useState } from 'react'
import HeadingViewAll from '../HeadingViewAll'
import Product from '../Product'

export const icons = {
  'immunity-throat-health': (
    <svg
      width="32"
      height="43"
      viewBox="0 0 32 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9178 36.1515C30.9549 35.8549 30.4062 34.9056 27.9144 33.4818C25.4226 32.0579 25.9863 28.8097 26.5795 27.3636C30.4729 19.3544 30.2504 14.1262 30.1392 12.9026C30.0279 11.6789 27.8032 1 16.7905 1C5.77786 1 3.44189 8.45301 2.88565 9.45416C2.4209 10.2906 2.44074 16.4622 2.44074 17.0184V17.0184C2.44074 18.1403 3.11313 19.2526 2.62297 20.2617L1.73397 22.092C1.61218 22.3427 1.45643 22.5754 1.31944 22.8181C0.899143 23.563 0.669017 24.7203 2.10698 25.2501C2.24431 25.3007 2.37083 25.3487 2.4873 25.3942C3.39535 25.7491 3.10822 26.6112 3.10822 27.5861V27.5861C3.10822 27.8086 4.44308 28.5872 4.44308 28.6985C4.44308 28.8097 3.77565 29.1434 3.88689 29.4772C3.99813 29.8109 4.88804 30.0334 4.66556 31.257C4.44308 32.4806 3.55317 34.5941 5.77795 35.0391C8.00273 35.4841 9.78255 34.7054 10.45 35.0391C11.1174 35.3728 11.3399 35.929 11.5624 36.5964C11.7404 37.1304 12.7489 40.2303 13.231 41.7134"
        stroke="#F6F6F6"
        strokeLinecap="round"
      ></path>
      <path
        d="M11.2305 27.9221C11.3788 26.4019 12.6543 23.3613 16.5699 23.3613"
        stroke="#F6F6F6"
        strokeLinecap="round"
      ></path>
      <path
        d="M8.89404 25.5871C9.78395 23.251 13.0544 19.0907 19.0168 21.1375"
        stroke="#F6F6F6"
        strokeLinecap="round"
      ></path>
      <path
        d="M15.5679 32.8168C17.2735 32.928 20.7071 32.0826 20.7961 27.811"
        stroke="#F6F6F6"
        strokeLinecap="round"
      ></path>
      <path
        d="M13.1211 34.931C15.42 36.0063 20.6631 36.6218 23.2438 30.4814"
        stroke="#F6F6F6"
        strokeLinecap="round"
      ></path>
      <path
        d="M17.8485 27.9225C17.8485 28.8751 17.0763 29.6473 16.1237 29.6473C15.1711 29.6473 14.3989 28.8751 14.3989 27.9225C14.3989 26.97 15.1711 26.1978 16.1237 26.1978C17.0763 26.1978 17.8485 26.97 17.8485 27.9225Z"
        stroke="#F6F6F6"
      ></path>
    </svg>
  ),
  'joint-health': (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8365 2L10.0499 13.2507C7.88011 14.7775 5.71036 17.992 6.03181 24.421C6.35045 30.7936 7.61877 41.7189 7.61877 42.0671"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6585 12.286L24.7559 24.5011C23.8719 25.3047 21.7343 27.5227 21.5414 32.8587C21.3485 38.1947 20.9454 39.713 20.5436 42.7132"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.015 5.1338L17.6036 16.3041C16.7464 15.5541 14.6462 14.5683 13.1033 16.6256C11.1746 19.1971 13.9873 21.6884 16.157 20.4829C15.916 21.6348 16.1249 23.9224 18.8893 23.8581C22.3449 23.7778 21.3806 19.84 20.2555 19.1971L31.6669 7.54466"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1402 42.0674V27.5947C12.1402 26.8486 11.7167 26.1756 11.104 25.7498C9.9252 24.9307 8.63975 23.6688 8.96142 22.4588C9.49122 20.4658 11.3834 21.8159 11.7618 22.0731C12.1402 22.3302 13.881 24.4518 16.1516 24.9018C17.3881 25.1469 18.0859 26.0022 17.9394 26.5953C17.626 27.8636 15.6218 28.2955 15.6218 29.6019V42.0674"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'man-health': (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_4)">
        <path
          d="M27.5584 37.5881C22.0651 42.9402 13.2472 42.8054 7.86313 37.2793C2.47905 31.7532 2.57377 22.9348 8.06705 17.5827C13.0701 12.7082 20.9226 12.5043 26.3445 16.684C26.6366 16.9091 27.0576 16.8941 27.3302 16.6285L33.2099 10.8999C33.5066 10.6108 33.5128 10.136 33.2237 9.8393L30.616 7.16285L39.0768 6.87861L38.7454 15.5067L36.3163 13.0135C36.0272 12.7168 35.5524 12.7106 35.2557 12.9996L29.3703 18.7338C29.1053 18.992 29.0724 19.3983 29.2736 19.6956C32.9928 25.1924 32.4412 32.8308 27.5584 37.5881ZM39.3454 6.86959C39.3452 6.8696 39.345 6.8696 39.3448 6.86961L39.3454 6.86959ZM39.0871 6.61073C39.0871 6.61053 39.0871 6.61033 39.0871 6.61013L39.0871 6.61073Z"
          stroke="#F6F6F6"
        />
        <path
          d="M10.8621 20.4518C14.7701 16.6443 21.0519 16.7448 24.8928 20.6869C28.7337 24.6291 28.6706 30.9114 24.7626 34.719C20.8546 38.5266 14.5728 38.4261 10.7319 34.4839C6.89108 30.5417 6.95411 24.2594 10.8621 20.4518Z"
          stroke="#F6F6F6"
        />
        <path
          d="M17.2008 30.2116L15.2539 32.1085L13.1271 29.9256L15.074 28.0287C15.3707 27.7396 15.3769 27.2648 15.0878 26.9681L13.1901 25.0203L15.2685 22.9954L17.1662 24.9431C17.4553 25.2398 17.9301 25.246 18.2268 24.9569L20.3807 22.8584L22.5075 25.0412L20.3536 27.1398C20.0569 27.4289 20.0507 27.9037 20.3398 28.2004L22.4393 30.3553L20.3609 32.3802L18.2614 30.2254C17.9723 29.9287 17.4975 29.9225 17.2008 30.2116Z"
          stroke="#F6F6F6"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_4">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  // 'diges-with-dipya': <Digestive />,
  'personal-health': (
    <svg
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 35.5385V30.127C1 28.7113 2.14765 27.5636 3.56335 27.5636C4.97904 27.5636 6.12669 28.7113 6.12669 30.127V35.5385C6.12669 36.9542 4.97904 38.1019 3.56335 38.1019C2.14765 38.1019 1 36.9542 1 35.5385Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M6.19774 28.6317C9.1171 27.9197 15.5397 26.5098 17.8752 26.5668C20.7946 26.638 23.5003 27.6348 23.9276 28.1333C24.3204 28.5915 25.5558 30.1332 23.4835 31.2088C23.0871 31.4145 22.6288 31.458 22.1841 31.4167C20.8472 31.2926 18.9698 31.1238 18.7297 31.1238C18.3736 31.1238 16.7359 30.9814 16.6647 32.1207C16.5935 33.26 17.3056 33.6872 18.0176 33.7584C18.5872 33.8153 21.6253 34.4467 23.0731 34.7552C23.5478 34.8027 24.8675 34.7837 26.3485 34.328C27.8295 33.8723 31.6651 33.1888 33.3977 32.9039C33.9911 32.809 35.2205 32.9324 35.3914 34.1856C35.605 35.7521 34.7506 36.2505 33.9673 36.4641C33.3407 36.635 25.5415 39.1462 21.7202 40.3804C21.1506 40.5643 19.4417 40.8788 16.3799 39.8107L6.19774 36.8202"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M10.683 27.635C8.00098 25.7125 3.54835 20.2013 7.194 13.5366C10.4896 7.51182 15.7217 8.45087 19.0121 10.4019C20.6272 11.3596 22.9641 11.5197 24.5635 10.536C25.3281 10.0658 26.1284 9.6838 26.8463 9.54919C27.0134 9.51787 27.2045 9.4877 27.416 9.4612M26.8463 34.1858C37.3133 24.4308 38.8798 21.7251 37.3845 15.3167C37.2835 14.8837 37.1584 14.4808 37.0122 14.1063"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M39.5009 8.85915C39.5009 12.0896 36.8497 14.7183 33.5669 14.7183C30.2842 14.7183 27.633 12.0896 27.633 8.85915C27.633 5.62873 30.2842 3 33.5669 3C36.8497 3 39.5009 5.62873 39.5009 8.85915Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M32.5189 5.94172V7.73725H30.5738V9.75721H32.5189V11.7024H34.6137V9.75721H36.4092V7.73725H34.6137V5.94172H32.5189Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M11.6804 21.5827H14.6277C15.1413 21.5827 15.6182 21.3163 15.8874 20.8788L16.2088 20.3566C16.6437 19.6498 17.673 19.6556 18.0999 20.3672V20.3672C18.5381 21.0974 19.6024 21.0797 20.016 20.3353L20.4522 19.55C21.084 18.4128 22.7293 18.4418 23.3207 19.6004L23.9771 20.8865C24.5975 22.1019 26.3201 22.1408 26.9947 20.9545V20.9545C27.2994 20.4185 27.8685 20.0875 28.4851 20.0875H32.6144"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
    </svg>
  ),
  'woman-health': (
    <svg
      width="31"
      height="43"
      viewBox="0 0 31 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1674 29.4644C6.34838 28.3444 1 22.4073 1 15.3059C1 7.40498 7.438 1 15.3797 1C23.3213 1 29.7593 7.40498 29.7593 15.3059C29.7593 22.5855 24.1467 28.5641 17.0757 29.4644"
        stroke="#F6F6F6"
      />
      <path
        d="M25.279 15.3066C25.279 20.7288 20.8511 25.1304 15.3814 25.1304C9.91174 25.1304 5.48379 20.7288 5.48379 15.3066C5.48379 9.88435 9.91174 5.48271 15.3814 5.48271C20.8511 5.48271 25.279 9.88435 25.279 15.3066Z"
        stroke="#F6F6F6"
      />
      <path
        d="M13.4384 13.6717C13.6455 13.6717 13.8134 13.5038 13.8134 13.2967V10.224H17.087V13.2967C17.087 13.5038 17.2549 13.6717 17.462 13.6717H20.5359V16.8003H17.462C17.2549 16.8003 17.087 16.9682 17.087 17.1753V20.5353H13.8134V17.1753C13.8134 16.9682 13.6455 16.8003 13.4384 16.8003H10.0771V13.6717H13.4384Z"
        stroke="#F6F6F6"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path
        d="M12.9577 28.9027L13.0194 33.1508H7.78368V37.2804H13.0194V41.9999H17.2226V37.2804H22.3846V33.1508H17.2226V28.9027"
        stroke="#F6F6F6"
      />
    </svg>
  ),
  digestion: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7197 7.91946L33.2163 7.9774L33.2197 7.94853V7.91946H32.7197ZM19.5095 11.7507C19.2333 11.7507 19.0095 11.9745 19.0095 12.2507C19.0095 12.5268 19.2333 12.7507 19.5095 12.7507V11.7507ZM28.7441 4.31011C28.7441 3.2954 29.537 2.50013 30.4819 2.50013V1.50013C28.955 1.50013 27.7441 2.77328 27.7441 4.31011H28.7441ZM30.4819 2.50013C31.4268 2.50013 32.2197 3.2954 32.2197 4.31011H33.2197C33.2197 2.77328 32.0088 1.50013 30.4819 1.50013V2.50013ZM32.2197 4.31011V7.91946H33.2197V4.31011H32.2197ZM32.2231 7.86152C32.1468 8.51483 31.8543 9.50854 31.2392 10.3258C30.6371 11.1258 29.7348 11.7507 28.3885 11.7507V12.7507C30.1028 12.7507 31.282 11.9318 32.0382 10.9272C32.7814 9.93973 33.1241 8.76782 33.2163 7.9774L32.2231 7.86152ZM28.3885 11.7507H19.5095V12.7507H28.3885V11.7507ZM27.7441 4.31011V6.40353H28.7441V4.31011H27.7441ZM27.7441 6.40353C27.7441 6.43853 27.7208 6.57169 27.5698 6.7027C27.4239 6.82939 27.1186 6.98634 26.5116 6.98634V7.98634C27.2907 7.98634 27.8516 7.78235 28.2254 7.45787C28.5942 7.13772 28.7441 6.72947 28.7441 6.40353H27.7441ZM26.5116 6.98634H23.6963V7.98634H26.5116V6.98634ZM23.6963 6.98634H16.9107V7.98634H23.6963V6.98634Z"
        fill="#F6F6F6"
      />
      <path
        d="M32.8642 17.7316C33.1403 17.7316 33.3642 17.5077 33.3642 17.2316C33.3642 16.9554 33.1403 16.7316 32.8642 16.7316V17.7316ZM17.5606 16.7316C15.3029 16.7316 13.2962 15.0001 13.2962 12.4672H12.2962C12.2962 15.5957 14.7949 17.7316 17.5606 17.7316V16.7316ZM13.2962 12.4672C13.2962 9.82892 14.8967 7.98635 17.0553 7.98635V6.98635C14.1905 6.98635 12.2962 9.44436 12.2962 12.4672H13.2962ZM17.5606 17.7316H32.8642V16.7316H17.5606V17.7316Z"
        fill="#F6F6F6"
      />
      <path
        d="M19.4374 12.2508L34.4523 12.3952C37.2431 12.3952 39.5054 14.6252 39.5054 17.3761C39.5054 20.127 37.2431 22.357 34.4523 22.357H11.4969"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M33.0086 17.3036H10.0532C7.26245 17.3036 5.0001 19.5336 5.0001 22.2845C5.0001 25.0353 7.26245 27.2654 10.0532 27.2654H28.1721"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M32.8641 27.3377C32.8641 24.5869 30.6018 22.3568 27.811 22.3568M17.1996 32.3186H26.7282"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M17.2717 37.2273C14.481 37.2273 12.2186 34.9972 12.2186 32.2464C12.2186 29.4955 14.481 27.2655 17.2717 27.2655"
        stroke="#F6F6F6"
      />
      <path
        d="M20.0148 32.3187C24.2306 32.3187 25.1401 35.134 25.0679 36.5056V40.2593C25.0679 41.6148 23.9691 42.7137 22.6136 42.7137C21.2581 42.7137 20.1592 41.6148 20.1592 40.2593V38.599C20.1592 38.0696 19.986 37.0253 19.293 37.0831C18.6 37.1408 17.7289 37.2034 17.1996 37.2275"
        stroke="#F6F6F6"
      />
      <path
        d="M38.4281 33.4012C38.4281 36.5083 35.878 39.0371 32.72 39.0371C29.562 39.0371 27.0119 36.5083 27.0119 33.4012C27.0119 30.2941 29.562 27.7653 32.72 27.7653C35.878 27.7653 38.4281 30.2941 38.4281 33.4012Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M32.8642 30.5858H32.5755C32.0971 30.5858 31.7093 30.9736 31.7093 31.452C31.7093 31.9304 31.3214 32.3183 30.843 32.3183H30.7708C30.2525 32.3183 29.8324 32.7384 29.8324 33.2567V33.3289C29.8324 33.8472 30.2525 34.2673 30.7708 34.2673C31.2891 34.2673 31.7093 34.6875 31.7093 35.2057C31.7093 35.724 32.1294 36.1442 32.6477 36.1442H32.7921C33.3103 36.1442 33.7305 35.724 33.7305 35.2057V35.1336C33.7305 34.6551 34.1183 34.2673 34.5967 34.2673C35.0751 34.2673 35.463 33.8795 35.463 33.4011V33.1845C35.463 32.7061 35.0751 32.3183 34.5967 32.3183C34.1183 32.3183 33.7305 31.9304 33.7305 31.452C33.7305 30.9736 33.3427 30.5858 32.8642 30.5858Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'sexual-health': (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_3)">
        <path
          d="M11.2262 33.3509C6.05552 32.4887 2 27.9178 2 22.4505C2 16.3677 6.88174 11.4366 12.9037 11.4366C18.9256 11.4366 23.8073 16.3677 23.8073 22.4505C23.8073 23.506 23.6564 24.5259 23.3756 25.4909M14.1897 33.3509C17.4732 32.9265 20.3419 31.0358 22.0725 28.3468"
          stroke="#F6F6F6"
        />
        <path
          d="M19.2858 27.1176C17.8527 29.1058 15.5278 30.3984 12.9035 30.3984C8.54914 30.3984 5.01927 26.8399 5.01927 22.4502C5.01927 18.0605 8.54914 14.502 12.9035 14.502C17.2578 14.502 20.7876 18.0605 20.7876 22.4502C20.7876 23.0828 20.7143 23.6981 20.5758 24.2881"
          stroke="#F6F6F6"
        />
        <path
          d="M11.1142 33.18V36.189H7.14418V39.3682H11.1142V43.0017H14.3015V39.3682H18.2156V36.189H14.3015V33.18"
          stroke="#F6F6F6"
        />
        <path
          d="M14.4786 14.8936C13.476 18.5 14.3897 22.5306 17.2361 25.4133C21.4998 29.7313 28.4856 29.8388 32.8393 25.6533C36.7524 21.8914 37.1525 15.8382 34.1087 11.5373L38.8629 6.9668L41.4759 9.61305L41.7793 1.81846L34.0329 2.07525L36.7647 4.84179L32.0104 9.41231C27.7181 6.04462 21.4104 6.16009 17.3991 10.0164C16.8217 10.5714 16.3197 11.1741 15.893 11.8121"
          stroke="#F6F6F6"
        />
        <path
          d="M17.3329 16.096C16.7791 18.5966 17.4752 21.3237 19.4303 23.3038C22.5133 26.426 27.5595 26.5086 30.7014 23.4881C33.8432 20.4677 33.8909 15.488 30.808 12.3658C27.725 9.2435 22.6788 9.16096 19.5369 12.1814C19.2218 12.4843 18.9379 12.8069 18.6851 13.1454"
          stroke="#F6F6F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3">
          <rect
            width="42"
            height="43"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  'oral-health': (
    <svg
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2673 21.1914L14.3276 32.6409C13.7087 34.4202 10.3822 35.8127 8.5255 32.0993L1.4856 13.2231C0.789344 11.3922 0.309702 6.91042 3.96117 3.63028C7.61263 0.350155 11.4137 0.871056 12.8577 1.54152C16.8032 3.78501 24.462 9.46335 26.6281 8.34934"
        stroke="#F6F6F6"
      />
      <path
        d="M26.5507 32.0574C24.694 35.7708 21.3675 34.3783 20.7486 32.5989L17.9339 21.4924C17.9022 21.3672 17.8665 21.2382 17.7796 21.1427C17.6419 20.9916 17.4232 20.9262 17.1899 21.3461M17.7315 4.48127C18.5979 3.7386 21.0838 2.18404 22.2184 1.4996C23.6625 0.829133 27.4635 0.308231 31.115 3.58836C34.7665 6.86849 34.2868 11.3503 33.5906 13.1812L30.4173 21.6898"
        stroke="#F6F6F6"
      />
      <path
        d="M4.88869 6.72479C4.21822 7.73049 3.18674 10.2989 4.42452 12.5269"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M5.97322 5.56449C6.2053 5.22926 6.88609 4.54332 7.75254 4.48143"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M37.5002 27.9992C37.5002 31.3492 34.7509 34.0749 31.3471 34.0749C27.9433 34.0749 25.194 31.3492 25.194 27.9992C25.194 24.6492 27.9433 21.9235 31.3471 21.9235C34.7509 21.9235 37.5002 24.6492 37.5002 27.9992Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
      <path
        d="M30.2637 24.9822V26.8389H28.2523V28.9276H30.2637V30.939H32.4298V28.9276H34.2865V26.8389H32.4298V24.9822H30.2637Z"
        stroke="#F6F6F6"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'stress-anxiety-sleep-disturbance': (
    <svg
      width="30"
      height="46"
      viewBox="0 0 30 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5556 23.0021C16.9826 22.2196 18.2026 21.124 19.6667 23.0021"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M10.3333 23.0021C10.7603 22.2196 11.9804 21.124 13.4444 23.0021"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M13.4444 28.1873C13.8715 27.4047 15.0915 26.3091 16.5556 28.1873"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M9.83608 34.4784L5.91936 34.718C1.91157 35.5485 0.970342 37.7792 1.0007 38.7907V44.7799H5.7372M9.83608 34.4784L14.4815 37.3533V44.7799H5.7372M9.83608 34.4784C10.3522 34.2389 11.2568 31.8585 11.1111 30.2614M5.7372 39.6691V44.7799"
        stroke="#F6F6F6"
      />
      <path
        d="M19.4842 34.1105L23.7022 34.3586C28.0183 35.2188 29.0319 37.5291 28.9992 38.5768V44.7799H23.8984M19.4842 34.1105L14.4815 37.088V44.7799H23.8984M19.4842 34.1105C18.9284 33.8624 17.675 31.9156 17.8319 30.2614M23.8984 39.4866V44.7799"
        stroke="#F6F6F6"
      />
      <path
        d="M15.5185 17.2984C14.3214 17.3564 10.1473 17.6145 9.09614 16.7799C8.36618 17.3015 7.0289 18.9012 7.51943 21.1268C6.4391 21.9672 4.99671 23.9957 7.86983 25.3867C7.75303 26.1691 7.79973 27.9079 8.92095 28.6034C10.3225 29.4727 13.0957 31.2115 14.9352 31.2984"
        stroke="#F6F6F6"
      />
      <path
        d="M15.0741 17.2984C16.1632 17.3564 19.1731 17.6145 20.1294 16.7799C20.7935 17.3015 22.0102 18.9012 21.5639 21.1268C22.5468 21.9672 23.859 23.9957 21.2451 25.3867C21.3514 26.1691 21.3089 27.9079 20.2888 28.6034C19.0138 29.4727 16.155 31.2115 14.4815 31.2984"
        stroke="#F6F6F6"
      />
      <path
        d="M7.16002 21.4569C5.66116 19.1413 4.77487 13.8157 13.2206 11.0371C14.0042 10.8618 15.6898 10.5812 17.319 10.5591C17.8035 10.5525 17.9727 11.1391 17.581 11.4243V11.4243C17.3381 11.601 17.5503 12.0494 17.8453 11.9931C19.2496 11.7253 20.9788 11.6029 22.2708 12.0565C23.0826 12.3415 23.1494 13.3342 23.0681 14.1907C23.0132 14.7698 23.096 15.429 23.4846 16.0351C24.4621 17.56 22.9959 21.9651 22.6048 21.9651"
        stroke="#F6F6F6"
      />
      <path
        d="M6.59964 9.52177L4.95589 7.23047C4.60268 6.73812 4.95457 6.0526 5.56051 6.0526V6.0526C6.15998 6.0526 6.51338 5.38001 6.1733 4.88634L4.63881 2.65885"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M13.915 7.93833L15.5245 5.57427C15.866 5.0728 15.5068 4.39375 14.9001 4.39375V4.39375C14.2916 4.39375 13.9328 3.71096 14.2781 3.20982L15.8004 1"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
      <path
        d="M25.0011 2.58391L23.5628 4.81579C23.2396 5.31729 23.5996 5.97766 24.1963 5.97766V5.97766C24.7982 5.97766 25.1572 6.6485 24.8233 7.14933L23.1911 9.59766"
        stroke="#F6F6F6"
        strokeLinecap="round"
      />
    </svg>
  ),
}

const breakpoints = {
  0: {
    slidesPerView: 1,
  },
  300: {
    slidesPerView: 2,
  },
  700: {
    slidesPerView: 3,
  },
  900: {
    slidesPerView: 4,
  },
  1000: {
    slidesPerView: 5,
  },
}
const ProductSlider = ({
  title,
  description,
  subTitle,
  items = [],
  categories = null,
  categorySlider = false,
  slides = null,
  filter = {},
  loading = false,
  onCategoryChange,
  stock = false,
  link,
  productCardVariant = null,
  enableProductsSlider = true,
  centerMode = false,
}) => {
  const [{ isMobile, isTablet, isDesktop }] = useAppContext()
  const [activeCategoryTab, setActiveCategoryTab] = useState(0)
  const removeActiveClass = (e) => {
    let swiperWrapper = e.target.parentElement
    if (e.target.tagName == 'A') {
      swiperWrapper = e.target.parentElement.parentElement
    }
    for (const child of swiperWrapper.children) {
      if (child.classList.contains('swiper-slide-active-custom')) {
        child.classList.remove('swiper-slide-active-custom')
      }
    }
  }
  if (!enableProductsSlider) {
    return (
      <div className="ProductsGrid">
        {items?.map((item) => (
          <div key={item.uid}>
            <ProductTile
              productCardVariant={productCardVariant}
              stock={stock}
              {...getProductDefaultCartItem(item)}
              // key={item.uid}
            />
            {/* <Product
            stock={stock}
            data={{ ...getProductDefaultCartItem(item) }}
          /> */}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className="product-list-slider">
      <div className="container">
        {title ? <HeadingViewAll title={title} link={link} /> : null}

        {description ? <p>{description}</p> : null}

        {categorySlider && categories?.length && (
          <div className="tabs-scroller-cat proudct-colletions">
            <ul
              className="nav nav-pills mb-4 mobile-full-carousel tabs-scroller-cat"
              id="myTab"
              role="tablist"
            >
              <MainSlider
                autoplay={true}
                spaceBetween={15}
                slidesPerView={'auto'}
                delay={4500}
              >
                {categories?.map((item, itemIndex) => {
                  return (
                    <SwiperSlide
                      key={item.url_key + itemIndex}
                      onClick={(e) => {
                        onCategoryChange(item)
                        setActiveCategoryTab(itemIndex)
                        removeActiveClass(e)
                      }}
                      className={`nav-item category-product-tab ${
                        activeCategoryTab == itemIndex
                          ? 'active swiper-slide-active-custom'
                          : ''
                      }`}
                    >
                      <button
                        className={`nav-link ${
                          activeCategoryTab == itemIndex ? 'active' : ''
                        }`}
                        id="first-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#first"
                        type="button"
                        role="tab"
                        aria-controls="first"
                        aria-selected="true"
                      >
                        <span className="icons">{icons[item?.url_key]}</span>
                        <span>{item.name}</span>
                      </button>
                    </SwiperSlide>
                  )
                })}
              </MainSlider>
            </ul>
          </div>
        )}

        <div className="product-list-items product-carousel">
          <MainSlider
            autoplay={false}
            spaceBetween={15}
            slidesPerView={2}
            breakpoints={breakpoints}
            fade={false}
            scrollbar={{ hide: true }}
          >
            {items?.map((item) => (
              <SwiperSlide key={item.uid}>
                <ProductTile
                  stock={stock}
                  {...getProductDefaultCartItem(item)}
                />
              </SwiperSlide>
            ))}
          </MainSlider>
        </div>
      </div>
    </div>
  )
}

export default ProductSlider
